import { callApi } from ".";

export const processCall = async ({ keys, language }) => {
  return await callApi({
    method: "POST",
    url: "/call/submit",
    requestBody: { keys, language },
    serializerFunc: (response: any) => response.data,
  });
};

export const getCall = async ({ callId }) => {
  return await callApi({
    method: "GET",
    url: `/call?callId=${callId}`,
    serializerFunc: (response: any) => response.data,
  });
};

export const getAllCalls = async () => {
  return await callApi({
    method: "GET",
    url: '/call/all',
    serializerFunc: (response: any) => response.data,
  });
};

export const deleteCall = async ({ callId }) => {
  return await callApi({
    method: "DELETE",
    url: `/call?callId=${callId}`,
    serializerFunc: (response: any) => response.data,
  });
}

export const getDashboardMetrics = async () => {
  return await callApi({
    method: "GET",
    url: `/call/dashboard-metrics`,
    serializerFunc: (response: any) => response.data,
  });
}