import AWS from "aws-sdk";
import React, { useEffect, useRef, useState } from 'react';
import { Layout, Card, Spin, Table, Button, Tabs } from 'antd';
import type { TabsProps } from "antd";
import { ColumnsType } from 'antd/es/table';
import { PlayCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import './CallAnalysis.css';
import { deleteCall, getCall } from '../../api/call.api';
import { RoutePath } from '../../routes';
import { useNavigate } from 'react-router-dom';


const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
  dirName: process.env.REACT_APP_S3_DIR_NAME,
  region: process.env.REACT_APP_AWS_REGION_NAME,
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
};

const { Content } = Layout;

interface TranscriptSnippet {
  start: number;
  end: number;
  speaker: string;
  text: string;
}

interface AnalysisItem {
  field: string;
  score: number;
  remarks: string;
}

interface AnalysisData {
  title: string;
  response: AnalysisItem[];
}

interface CallAnalysis {
  _id: string;
  teamId: string;
  key: string;
  duration: number;
  timestamp: number;
  callScore: number;
  transcript: TranscriptSnippet[];
  processStatus: string;
  analysis: AnalysisData[];
}

const CallAnalysis: React.FC = () => {
    const callId = new URL(window.location.href).searchParams.get('callId');
    if (!callId) {
        window.location.href = RoutePath.HOME;
    }
    const navigate = useNavigate()
    const [audioUrl, setAudioUrl] = useState<string>("");
    const [audioTimestamp, setAudioTimestamp] = useState< { start: number, end: number } |null>(null);
    const [callAnalysis, setCallAnalysis] = useState<CallAnalysis>();
    const [tabItems, setTabItems] = useState<TabsProps["items"]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        getCall({ callId }).then(async ({call}: { call: CallAnalysis }) => {
          const signedUrl = await getSignedUrlForRead(call.key)
          setAudioUrl(signedUrl);
          setCallAnalysis(call);
          setIsLoading(false);
          setTabItems(call.analysis.map((a,n) => {
            return {
              key: `${n+1}`,
              label: a.title,
              children: <Table columns={tableColumns} dataSource={a.response} rowKey="agentId" />
            }      
          }))
        });
    }, [])
    
    useEffect(() => {
      if (audioRef.current && audioTimestamp !== null) {
        const audio = audioRef.current;
        const handleTimeUpdate = () => {
          if (audio.currentTime >= audioTimestamp.end) {
            audio.pause();
            audio.removeEventListener("timeupdate", handleTimeUpdate);
          }
        };
        audio.currentTime = audioTimestamp.start;
        audio.play();
        audio.scrollIntoView({ behavior: "smooth" });
        audio.addEventListener("timeupdate", handleTimeUpdate);
        return () => {
          audio.removeEventListener("timeupdate", handleTimeUpdate);
        };
      }
    }, [audioTimestamp]);

    const handlePlayAudioClick = (start: number, end: number) => {
        setAudioTimestamp({start, end});
    }
    
    const getSignedUrlForRead = async (fileKey: string): Promise<string> => {
      const s3 = new AWS.S3({
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey,
        region: config.region,
      });

      const params = {
        Bucket: config.bucketName,
        Key: fileKey,
        Expires: 60 * 10, // URL expiration time in seconds (10 minutes here)
      };

      return new Promise((resolve, reject) => {
        s3.getSignedUrl("getObject", params, (err, url) => {
          if (err) {
            reject(err);
          } else {
            resolve(url);
          }
        });
      });
    };
  
    const handleDeleteClick = async () => {
      try {
        const deleteResponse = await deleteCall({ callId });
        console.log(deleteResponse); 
        alert("Call deleted successfully");
        navigate(RoutePath.DASHBOARD)
      }
      catch (error) {
        console.log(error);
        alert("Error deleting call");
      }
    }
    
    const tableColumns: ColumnsType<AnalysisItem> = [
      {
        title: "Field",
        dataIndex: "field",
        key: "field",
      },
      {
        title: "Score",
        dataIndex: "score",
        key: "score",
        // render: (value: boolean) => {
        //   return <span>{value ? "✅" : "❌"}</span>;
        // },
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
      },
    ];
    
    // const tabItems: TabsProps["items"] = callAnalysis.analysis.map((a,n) => {
    //   const tableData: AnalysisTableData[] = Object.entries(a.response).map(([key, value]) => ({
    //     field: key,
    //     value: value.value,
    //     remarks: value.remarks
    //   }))
    //   return {
    //     key: `${n}`,
    //     label: a.title,
    //     children: <Table columns={tableColumns} dataSource={tableData} rowKey="agentId" />
    //   }      
    // });
    
    return (
      <div style={{ padding: "20px" }}>
        <h2>Call Analysis</h2>
        {/* <div className="delete-button-div">
          <Button onClick={handleDeleteClick}>
            <DeleteOutlined />
          </Button>
        </div> */}
        <div className="call-analysis-main">
          <div>
            {isLoading ? (
              <Spin size="large" />
            ) : (
              <div className="analysis-tabs">
                <Tabs defaultActiveKey="1" items={tabItems}/>
              </div>
            )}
          </div>
          <div className="play-call-box">
            <Card title="Play Call" style={{ height: "600px" }}>
              {audioUrl && (
                <audio ref={audioRef} controls id="callAudio">
                  <source src={audioUrl} type="audio/mpeg" />
                </audio>
              )}
              <div className="transcript-box" style={{ marginTop: "20px" }}>
                {isLoading ? (
                  <Spin size="large" />
                ) : (
                  callAnalysis.transcript.map((snippet, index) => (
                    <div
                      key={index}
                      data-start={snippet.start}
                      data-end={snippet.end}
                      id={`snippet-${index}`}
                    >
                      <strong>{snippet.speaker}:</strong> {snippet.text}
                    </div>
                  ))
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
};

export default CallAnalysis;
