import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import "./AllCalls.css";
import { Link } from "react-router-dom";
import { getAllCalls } from "../../api/call.api";
import { RoutePath } from "../../routes";
import { CompletedTag, ProcessingTag, WaitingTag, FailedTag } from "./statusTags";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface CallData {
  id:string
  key: string;
  timestamp: string;
  duration: number;
  score: string;
  status: "completed" | "processing" | "waiting" | "failed";
}

const AllCalls: React.FC = () => {
  const [data, setData] = useState<CallData[]>([]);


  useEffect(() => {
    getAllCalls().then((data) => {
      const calls = data.calls.map((call: any) => {
        return {
          id: call._id,
          key: call.key,
          timestamp: new Date(call.timestamp ?? Date.now()).toLocaleString(),
          duration: call.duration,
          score: `${Math.round((call.callScore / call.totalScore)*10000)/100}%`,
          status: call.processStatus,
        };
      });
      setData(calls.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()));
    });
  }, []);

  const columns: ColumnsType<CallData> = [
    {
      title: "Call",
      dataIndex: "key",
      key: "key",
      render: (key: string) => {
        return <span>{key.split('/')[1]}</span>
      },
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Call Date & Time",
      dataIndex: "timestamp",
      key: "timestamp",
    },
    {
      title: "Processing Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        if (status === "completed") {
          return <CompletedTag />;
        } else if (status === "processing") {
          return <ProcessingTag />;
        } else if (status === "waiting") {
          return <WaitingTag />;
        } else if (status === "failed") {
          return <FailedTag />;
        }
      },
    },
    {
      dataIndex: "id",
      key: "id",
      render: (id: string, record: CallData) => {
        if (record.status === "completed") {
          return (
            <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${id}`}>
              View Call
            </Link>
          );
        } else {
          return <span></span>;
        }
      },
    },
  ];

  return (
    <div className="main-content">
      <h2>All Calls</h2>
      <Table columns={columns} dataSource={data} rowKey="agentId" />
    </div>
  );
};

export default AllCalls;
