import React, { useEffect, useState } from "react";
import { Avatar, Button, Card, Dropdown, Layout, Menu, MenuProps } from 'antd';
import {
  FundProjectionScreenOutlined,
  PhoneOutlined,
  CaretDownOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import './Sidebar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../routes';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { clearUser } from "../../redux/slices/user";

const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState('1');
  const location = useLocation();
  const navigate = useNavigate();
    
  const { name, photoUrl } = useSelector((state: RootState) => state.persisted.user.value);
  const dispatch = useDispatch();

  const LogoutButton = () => {
    return (
      <Button className="logout-button" onClick={() => {
        dispatch(clearUser());  
        navigate(RoutePath.HOME);
      }}>
        <span>Logout</span>
      </Button>
    );
  }
  
  const userMenuItems:MenuProps['items'] = [
    {
      label: <LogoutButton/>,
      key: '0'
    }
  ]

    useEffect(() => {
      switch (location.pathname) {
        case RoutePath.DASHBOARD:
          setSelectedKey("1");
          break;
        case RoutePath.ALL_CALLS:
          setSelectedKey("2");
          break;
        case RoutePath.UPLOAD_CALL:
          setSelectedKey("3");
          break;
        default:
          setSelectedKey(null);
      }
    }, [location]);

    return (
      <Sider
        width={220}
        className="sidebar"
        style={{ background: "#fff", position: "fixed" }}
      >
        <div className="logo">
          <img
            src="https://cdn.worldvectorlogo.com/logos/mobikwik-logo.svg"
            alt="Groww Logo"
            style={{ width: "150px", marginBottom: "30px" }}
          />
        </div>
        <div className="sidebar-content">
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            style={{ borderRight: 0 }}
          >
            <Menu.Item key="1" icon={<FundProjectionScreenOutlined />}>
              <Link to={RoutePath.DASHBOARD}>Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<PhoneOutlined />}>
              <Link to={RoutePath.ALL_CALLS}>All Calls</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<UploadOutlined />}>
              <Link to={RoutePath.UPLOAD_CALL}>Upload Calls</Link>
            </Menu.Item>
          </Menu>
          <div className="user-section">
            <Dropdown menu={{ items: userMenuItems }} trigger={["click"]}>
              <div className="user-card">
                <Avatar src={photoUrl} />
                <div style={{ paddingLeft: "15px", alignSelf: "center" }}>
                  {name.split(" ")[0]} &nbsp;
                  <CaretDownOutlined />
                </div>
              </div>
            </Dropdown>
            <a href="https://recontact.world" style={{ marginTop: "10px" }}>
              <img
                width="150"
                src="https://s3.amazonaws.com/recontact.world/powered-by-recontact-dark.svg"
                alt="Powered by Recontact"
              />
            </a>
          </div>
        </div>
      </Sider>
    );
};

export default Sidebar;
