import { Layout } from "antd";
import Sidebar from "./components/Sidebar";
import UploadCalls from "./components/UploadCalls";
import CallAnalysis from "./components/CallAnalysis";
import AllCalls from "./components/AllCalls";
import ProtectedRoute from "./components/ProtectedRoute";
import LiveAbuseDetection from "./components/LiveAbuseDetection";
import LiveAgentAssist from "./components/LiveAgentAssist";
import GoogleOAuth from "./components/GoogleOAuth";
import Dashboard from "./components/Dashboard";

export type RouteConfig = {
  path: string;
  element: React.ReactNode;
};

export enum RoutePath {
  HOME = "/",
  DASHBOARD = "/dashboard",
  ALL_CALLS = "/calls/all",
  UPLOAD_CALL = "/upload",
  CALL_ANALYSIS = "/call-analysis",
  LIVE_ABUSE_DETECTION = "/live-abuse-detection",
  LIVE_AGENT_ASSIST = "/live-agent-assist",
  NOT_FOUND = "*",
}

interface PageLayoutProps {
  component: React.ComponentType;
}

const PageLayout: React.FC<PageLayoutProps> = ({ component: Component }) => (
  <Layout style={{ minHeight: "100vh" }}>
    <Sidebar />
    <Layout style={{ marginInlineStart: 220 }}>
      <Component />
    </Layout>
  </Layout>
);

export const routes: RouteConfig[] = [
  { path: RoutePath.HOME, element: <GoogleOAuth /> },
  {
    path: RoutePath.DASHBOARD,
    element: (
      <ProtectedRoute element={<PageLayout component={Dashboard} />} />
    ),
  },
  {
    path: RoutePath.ALL_CALLS,
    element: <ProtectedRoute element={<PageLayout component={AllCalls} />} />,
  },
  {
    path: RoutePath.UPLOAD_CALL,
    element: (
      <ProtectedRoute element={<PageLayout component={UploadCalls} />} />
    ),
  },

  {
    path: RoutePath.CALL_ANALYSIS,
    element: (
      <ProtectedRoute element={<PageLayout component={CallAnalysis} />} />
    ),
  },
  {
    path: RoutePath.LIVE_ABUSE_DETECTION,
    element: (
      <ProtectedRoute element={<PageLayout component={LiveAbuseDetection} />} />
    ),
  },
  {
    path: RoutePath.LIVE_AGENT_ASSIST,
    element: (
      <ProtectedRoute element={<PageLayout component={LiveAgentAssist} />} />
    ),
  },
  {
    path: RoutePath.NOT_FOUND,
    element: (
      <ProtectedRoute
        element={<PageLayout component={() => <h2>Not Found</h2>} />}
      />
    ),
  },
];
