import React, { useState, useEffect } from "react";
import { Layout, Card, Row, Col, Spin, TableProps, Table } from "antd";
import "./Dashboard.css"; // Custom styles
import { Link } from "react-router-dom";
import { RoutePath } from "../../routes";
import { getDashboardMetrics } from "../../api/call.api";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";


const { Content } = Layout;

interface DashboardMetrics {
  totalCalls: number,
  avgCallDuration: string,
  avgCallScore: string,
  qaMetrics: Array<{
    name: string,
    value: number
  }>
}

const Dashboard: React.FC = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [dashboardMetrics, setDashboardMetrics] = useState<DashboardMetrics>()

  useEffect(() => {
    getDashboardMetrics().then(data => {
      const qaMetricsCounts = data.metrics.fieldFailedCounts.find(d => d.title === "Agent QA").counts
      const parsedMetrics = {
        totalCalls: data.metrics.totalCalls,
        avgCallDuration: `${Math.floor(data.metrics.avgCallDuration / 60)}m ${Math.round(data.metrics.avgCallDuration % 60)}s`,
        avgCallScore: `${Math.round(data.metrics.avgCallScore * 10000) / 100}%`,
        qaMetrics: Object.entries(qaMetricsCounts).map(([key, value]) => {
          return { name: key, value: value as number}
        }).sort((a, b) => b.value - a.value)
      };
      setDashboardMetrics(parsedMetrics)
      setIsLoading(false)
    })
  }, [])
  
  const BarGraph = ({ data }) => {
    const renderCustomAxisTick = ({ x, y, payload }: any) => {
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={10}
            textAnchor="end"
            transform="rotate(-45)"
            fill="#666"
          >
            {payload.value}
          </text>
        </g>
      );
    };

    return (
      <div style={{ textAlign: "center" }}>
        <BarChart
          width={700}
          height={500}
          data={data}
          margin={{
            right: 30,
            bottom: 100,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={renderCustomAxisTick} interval={0} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </div>
    );
  };

  return (
    <Content className="main-content">
      <h2>Call Auditing Dashboard</h2>
      <Row gutter={[16, 16]} style={{ rowGap: "20px" }}>
        <Col span={8}>
          <Link to={RoutePath.ALL_CALLS}>
            <Card className="metric-card">
              {isLoading ? (
                <Spin size="small" />
              ) : (
                <>
                  <div className="card-title">Total Number of Calls</div>
                  <p>{dashboardMetrics.totalCalls}</p>
                </>
              )}
            </Card>
          </Link>
        </Col>
        <Col span={8}>
          <Card className="metric-card">
            {isLoading ? (
              <Spin size="small" />
            ) : (
              <>
                <div className="card-title">Average Call Time</div>
                <p>{dashboardMetrics.avgCallDuration}</p>
              </>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card className="metric-card">
            {isLoading ? (
              <Spin size="small" />
            ) : (
              <>
                <div className="card-title">Average Call Score</div>
                <p>{dashboardMetrics.avgCallScore}</p>
              </>
            )}
          </Card>
        </Col>
        <Col span={16}>
          <Card className="chart-card">
            {isLoading ? (
              <Spin size="small" />
            ) : (
              <>
                <div className="card-title" style={{ paddingBottom: "15px" }}>
                  Call sections with most errors by agents 
                </div>
                <BarGraph data={dashboardMetrics.qaMetrics} />
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default Dashboard;
